import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import qs from "query-string"
import { baseURL } from "utils/axios"

// Define a service using a base URL and expected endpoints
export const Api = createApi({
  reducerPath: "api/v1",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    credentials: "include",
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: "bracket" })
    },
  }),
  tagTypes: ["property", "visits", "enquiries", "bookings", "enquiries", "invoice", "support.tenant", "tenant", "meter_readings", "pin_code", "ledger"],
  endpoints: builder => ({
    // user in dashboard
    getLedger: builder.query({
      query: params => ({ url: `/ledger`, params }),
    }),
  }),
})

export const { selectInvalidatedBy, invalidateTags, updateQueryData } = Api.util
export const { useGetLedgerQuery } = Api
