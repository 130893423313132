import axios from "axios"
import { unAuthUser } from "../redux/User/actions/UserAuth"
import store from "../redux/store"

export const baseURL = process.env.REACT_APP_SERVER_ENDPOINT
export const clientServerURL = process.env.REACT_APP_CLIENT_SERVER_ENDPOINT
axios.defaults.baseURL = baseURL
axios.defaults.withCredentials = true
// axios.defaults.headers.common["Authorization"] = AUTH_TOKEN

axios.interceptors.response.use(undefined, function (error) {
  if (error?.response?.status === 401) {
    console.log("auto log out..")
    store.dispatch(unAuthUser())
  }
  return Promise.reject(error)
})
