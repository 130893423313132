import { TODAY_MOVE_IN_LIST, TODAY_MOVE_OUT_LIST, TODAY_VISIT_LIST } from "../constants"

const initialState = {
  todayVisitList: "",
  todayMoveInList: "",
  todayMoveOutList: "",
}

export default function visitReducer(state = initialState, actions) {
  switch (actions.type) {
    case TODAY_VISIT_LIST:
      return {
        ...state,
        todayVisitList: actions.payload,
      }
    case TODAY_MOVE_IN_LIST:
      return {
        ...state,
        todayMoveInList: actions.payload,
      }
    case TODAY_MOVE_OUT_LIST:
      return {
        ...state,
        todayMoveOutList: actions.payload,
      }

    default:
      return state
  }
}
